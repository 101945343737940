import {
  inject,
  Injectable,
  makeStateKey,
  signal,
  TransferState,
} from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  HomepageMetadata,
  PopularGameModel,
  ProviderImageModel,
} from '../models/homepage.model';
import { ClientHttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class HomepageService {
  private url = '/client-app-svc/v1';
  private http = inject(ClientHttpService);
  private getMetadataCache$: Observable<any> | null = null;
  private getProviderImageCache$: Observable<ProviderImageModel[]> | null =
    null;

  metadata = signal<HomepageMetadata>(null as unknown as HomepageMetadata);
  popularGames = signal<PopularGameModel[]>([]);
  providerImages = signal<ProviderImageModel[]>([]);

  transferState = inject(TransferState);

  getMetadata(): Observable<HomepageMetadata> {
    const data = this.metadata();

    const key = makeStateKey<HomepageMetadata>('metadata');

    const serverData = this.transferState.get(key, null);

    if (data || serverData) {
      return of(data || serverData);
    }
    if (!this.getMetadataCache$) {
      this.getMetadataCache$ = this.http
        .get<{ data: HomepageMetadata }>(`${this.url}/metadata`, true)
        .pipe(
          map((res) => {
            this.metadata.set(res.data);

            this.transferState.set(key, res.data);
            return res.data;
          }),
          shareReplay(1)
        );
    }

    return this.getMetadataCache$;
  }

  getProviderImage(): Observable<ProviderImageModel[]> {
    const url = `${this.url}/provider-imgs`;

    const key = makeStateKey<ProviderImageModel[]>('provider-imgs');

    const serverData = this.transferState.get(key, null);

    const data = this.providerImages();
    if (data.length || serverData) {
      return of(data || serverData);
    }

    if (!this.getProviderImageCache$) {
      this.getProviderImageCache$ = this.http
        .get<{ data: ProviderImageModel[] }>(url, true)
        .pipe(
          map((res) => {
            this.providerImages.set(res.data);
            this.transferState.set(key, res.data);
            return res.data;
          }),
          shareReplay(1)
        );
    }

    return this.getProviderImageCache$;
  }

  getPopularGames(): Observable<PopularGameModel[]> {
    const url = `${this.url}/popular-games`;

    const key = makeStateKey<PopularGameModel[]>('popular-games');

    const serverData = this.transferState.get(key, null);

    if (serverData) {
      return of(serverData);
    }

    return this.http.get<{ data: PopularGameModel[] }>(url, true).pipe(
      map((val) => {
        this.transferState.set(key, val.data);
        return val.data;
      })
    );
  }

  // test lint,   should removed later
}
